import styles from './MenuItem.module.css';
import React from 'react';

import ToolTip from '../ToolTip';

function ConditionalTooltip({
	tooltip,
	children,
}: {
	tooltip?: string,
	children: React.ReactNode,
}) {
	return tooltip ? <ToolTip title={tooltip}><div>{children}</div></ToolTip> : <>{children}</>;
};

export default function MenuItem({
	children,
	disabled = false,
	tooltip,
	onClick,
}: {
	children: React.ReactNode,
	disabled?: boolean,
	tooltip?: string,
	onClick?: React.MouseEventHandler<HTMLButtonElement>,
}) {
	return (
		<ConditionalTooltip tooltip={tooltip}>
			<button className={styles.container} disabled={disabled} onClick={onClick}>
				{children}
			</button>
		</ConditionalTooltip>
	);
}