import styles from './SearchProjectSiteForm.module.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import SearchPopover from '../../molecules/SearchPopover';
import ProjectSiteCard from '../../molecules/ProjectSiteCard';

import { graphql } from '../../../types/gql';

const SearchProjectSites_Query = graphql(`#graphql
	query searchProjectSites($query: String! $first: Int, $after: String) {
		searchProjectSites(query: $query, first: $first, after: $after) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			edges {
				node {
					id
					...ProjectSiteCard_ProjectSiteFragment
				}
			}
		}
	}
`);

export default function SearchProjectSiteForm() {
	const [searchAllProductRegistrations, { data, loading, refetch, fetchMore, called }] = useLazyQuery(SearchProjectSites_Query, {
		notifyOnNetworkStatusChange: true,
	});

	const [query, setQuery] = useState<string | null>('');

	useEffect(() => {
		if (!query) {
			return;
		}

		if (called) {
			refetch({
				query
			});
		}
		else {
			searchAllProductRegistrations({
				variables: {
					query,
				}
			});
		}
	}, [query, called, refetch, searchAllProductRegistrations]);

	const onSearch = (name: string, value: string | null) => setQuery(value);

	const loadMore = useCallback(() => {
		fetchMore({
			variables: {
				after: data?.searchProjectSites.pageInfo.endCursor
			}
		});
	}, [data, fetchMore]);

	return (
		<div className={styles.container}>
			<SearchPopover
				fieldName="search-project-sites"
				fieldPlaceholder="Zoek op naam en url"
				onSearch={onSearch}
				loading={loading}
				query={query ?? undefined}
				totalCount={data?.searchProjectSites.totalCount}
				results={data ? data.searchProjectSites.edges.map(p => (
					<ProjectSiteCard key={p.node.id} projectSite={p.node} />
				)) : []}
				onLoadMore={(data?.searchProjectSites.pageInfo.hasNextPage && loadMore) || undefined}
			/>
		</div>
	);
}
