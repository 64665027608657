import styles from './SearchPopover.module.css';
import React, { useState, useCallback, useEffect, useRef, ReactElement } from 'react';

import SearchField from '../SearchField';
import SearchResults from '../SearchResults';
import SearchDropDown from '../SearchDropDown';

// import { FieldValue } from  '../../../hooks/types';

export default function SearchPopover({
	fieldPlaceholder,
	fieldName = 'search',
	query = '',
	onSearch,
	resizeInputOnFocus = true,
	loading = false,
	totalCount,
	results = [],
	onLoadMore,
	maxHeight,
}: {
	fieldPlaceholder?: string,
	fieldName?: string,
	query?: string,
	onSearch: (name: string, query: string | null) => void,
	resizeInputOnFocus?: boolean,
	loading?: boolean,
	totalCount?: number,
	results?: ReactElement[],
	onLoadMore?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>,
	maxHeight?: React.CSSProperties['maxHeight'],
}) {
	const [showDropDown, setShowDropDown] = useState(false);
	const [isFocused, setFocused] = useState(false);
	const dropDownContainer = useRef<HTMLDivElement | null>(null);

	const onDocumentClick = useCallback((event: MouseEvent) => {
		if (!dropDownContainer?.current) {
			return;
		}

		// @ts-ignore
		if (!dropDownContainer?.current.contains(event.target)) {
			setShowDropDown(false);
			setFocused(false);
		}
	}, [dropDownContainer]);

	useEffect(() => {
		document.addEventListener('click', onDocumentClick);

		return () => {
			document.removeEventListener('click', onDocumentClick);
		};
	}, []);

	const onFocusChange: React.FocusEventHandler<HTMLInputElement> = (event) => {
		if (event.type === 'focus') {
			setFocused(true);
			setShowDropDown(!!query);
		}
	};

	useEffect(() => {
		setShowDropDown(!!query);
	}, [query]);

	return (
		<div ref={dropDownContainer} className={styles.container}>
			<SearchField name={fieldName} placeholder={fieldPlaceholder} onChange={onSearch} isFocused={isFocused} onFocusChange={onFocusChange} resizeInputOnFocus={resizeInputOnFocus} />
			<SearchDropDown show={showDropDown} maxHeight={maxHeight}>
				<SearchResults
					query={query}
					results={results}
					totalCount={totalCount}
					loading={loading}
					onLoadMore={onLoadMore}
				/>
			</SearchDropDown>
		</div>
	);
}
