import styles from './SearchDropDown.module.css';

export default function SearchDropDown({
	children,
	show = false,
	maxHeight, // TODO: better to to solve this without asking the user of this component
}: {
	children: React.ReactNode,
	show?: boolean,
	maxHeight?: React.CSSProperties['maxHeight'],
}) {
	return (
		<div
			className={`${styles.container}${show ? ` ${styles.isVisible}` : ''}`}
			// TODO: DRY calc value
			style={maxHeight ? { maxHeight: `min(calc(85vh - 80px), ${maxHeight})` } : undefined}
		>
			{children}
		</div>
	);
};