import styles from './Label.module.css';

export default function Label({
	children,
	className = '',
}: {
	children: React.ReactNode,
	className?: string,
}) {
	return (
		<span className={`${styles.container} ${className}`.trim()}>
			{children}
		</span>
	);
}