import styles from './ProjectSiteModal.module.css';

import Modal from '../Modal';
import RealEstateObjectCard from '../RealEstateObjectCard';
import ProjectCard from '../ProjectCard';

import Alert from '../../atoms/Alert';
import Skeleton from '../../atoms/Skeleton';

import { FragmentType, useFragment } from '../../../types/gql/fragment-masking';
import { graphql } from '../../../types/gql';

const ProjectSiteModal_ProjectSiteFragment = graphql(/* GraphQL */ `
	fragment ProjectSiteModal_ProjectSiteFragment on ProjectSite {
		id
		name
		projects {
			id
			...ProjectCard_ProjectFragment
		}
		realEstateObjects {
			id
			...RealEstateObjectCard_RealEstateObjectFragment
		}
	}
`);

export default function ProjectSiteModal({
	projectSite,
	isOpen = false,
	onHide,
}: {
	projectSite?: FragmentType<typeof ProjectSiteModal_ProjectSiteFragment> | null,
	isOpen?: boolean,
	onHide: () => void,
}) {
	const site = useFragment(ProjectSiteModal_ProjectSiteFragment, projectSite);

	return (
		<Modal
			title={site?.name || <Skeleton/>}
			isOpen={isOpen}
			onHide={onHide}
			buttons={[
				{
					label: 'Close',
					type: 'secondary',
					onClick: () => onHide(),
				}
			]}
		>
			{site && <div className={styles.sections}>
				<div>
					<h3>Projects</h3>
					{site?.projects && site?.projects.length > 0 ? <ul className={styles.cardList}>
						{site.projects.map(project => (
							<li key={project.id}><ProjectCard project={project} /></li>
						))}
					</ul> : <Alert>No projects found</Alert> }
				</div>
				<div>
					<h3>Real estate objects</h3>
					{site?.realEstateObjects && site?.realEstateObjects.length > 0 ? (
						<ul className={styles.cardList}>
							{site?.realEstateObjects?.map(object => (
								<li key={object.id}><RealEstateObjectCard realEstateObject={object} /></li>
							))}
						</ul>
					): <Alert>No real estate objects found</Alert>}
				</div>
			</div>}
		</Modal>
	);
}
