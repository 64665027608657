import styles from './InputContainer.module.css';
import { MouseEventHandler } from 'react';

export default function InputContainer({
	children,
	state,
	disabled = false,
	canDisabledBeOverwritten = false,
	onEditDisabled,
	className,
} : {
	children: React.ReactNode,
	state?: 'isLoading' | 'isValidated' | 'isError' | 'isWarning',
	disabled?: boolean,
	canDisabledBeOverwritten?: boolean,
	onEditDisabled?: MouseEventHandler<HTMLButtonElement>,
	className?: string,
}) {
	return (
		<div className={`${styles.container}${state ? ` ${styles[state]}` : ''}${className ? ` ${className}` : ''}`}>
			{children}
			{disabled && canDisabledBeOverwritten && <button onClick={onEditDisabled} className={styles.editButton}>bewerk</button>}
		</div>
	);
}
