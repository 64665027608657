import styles from './ProjectCard.module.css';

import Label from '../../atoms/Label';

import { FragmentType, useFragment } from '../../../types/gql/fragment-masking';
import { graphql } from '../../../types/gql';

const ProjectCard_ProjectFragment = graphql(/* GraphQL */ `
	fragment ProjectCard_ProjectFragment on Project {
		name
		status
		description
		realEstateObjects {
			id
		}
	}
`);

export default function ProjectCard({
	project,
} : {
	project: FragmentType<typeof ProjectCard_ProjectFragment>,
}) {
	const { name, status, description, realEstateObjects } = useFragment(ProjectCard_ProjectFragment, project);

	return (
		<div className={styles.container}>
			<header>{name} <Label>{status}</Label></header>
			<div className={styles.body}>
				{Array.isArray(realEstateObjects) && <p>
					<strong>{realEstateObjects.length}</strong> object(s).
				</p>}
				<p>
					{description}
				</p>
			</div>
		</div>
	);
}
