/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "#graphql\n\tquery projectSites($first: Int! $after: String) {\n\t\tprojectSites(first: $first, after: $after) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\t...ProjectSiteCard_ProjectSiteFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.ProjectSitesDocument,
    "\n\tquery projectSiteForModal($id: ID!) {\n\t\tgetProjectSite(id: $id) {\n\t\t\t...ProjectSiteModal_ProjectSiteFragment\n\t\t}\n\t}\n": types.ProjectSiteForModalDocument,
    "\n\tquery projectSitesForModuleStats($first: Int $after: String $hasUrl: Boolean) {\n\t\tprojectSites(first: $first, after: $after, hasUrl: $hasUrl) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.ProjectSitesForModuleStatsDocument,
    "\n\tquery modulesByProjectSite($id: ID!) {\n\t\tmodulesByProjectSite(id: $id) {\n\t\t\tid\n\t\t\ttitle\n\t\t\ttype\n\t\t}\n\t}\n": types.ModulesByProjectSiteDocument,
    "\n\tfragment ProjectCard_ProjectFragment on Project {\n\t\tname\n\t\tstatus\n\t\tdescription\n\t\trealEstateObjects {\n\t\t\tid\n\t\t}\n\t}\n": types.ProjectCard_ProjectFragmentFragmentDoc,
    "\n\tfragment ProjectSiteCard_ProjectSiteFragment on ProjectSite {\n\t\tid\n\t\tname\n\t\turl\n\t\tthemeVersion\n\t\tlastSyncedOn\n\t\tscreenCapture\n\t\trepository {\n\t\t\turl\n\t\t}\n\t}\n": types.ProjectSiteCard_ProjectSiteFragmentFragmentDoc,
    "\n\tfragment ProjectSiteModal_ProjectSiteFragment on ProjectSite {\n\t\tid\n\t\tname\n\t\tprojects {\n\t\t\tid\n\t\t\t...ProjectCard_ProjectFragment\n\t\t}\n\t\trealEstateObjects {\n\t\t\tid\n\t\t\t...RealEstateObjectCard_RealEstateObjectFragment\n\t\t}\n\t}\n": types.ProjectSiteModal_ProjectSiteFragmentFragmentDoc,
    "\n\tfragment RealEstateObjectCard_RealEstateObjectFragment on RealEstateObject {\n\t\tid\n\t\tnumber\n\t\tstatus\n\t\tprice\n\t}\n": types.RealEstateObjectCard_RealEstateObjectFragmentFragmentDoc,
    "#graphql\n\tquery searchProjectSites($query: String! $first: Int, $after: String) {\n\t\tsearchProjectSites(query: $query, first: $first, after: $after) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\thasPreviousPage\n\t\t\t\tstartCursor\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\t...ProjectSiteCard_ProjectSiteFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.SearchProjectSitesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "#graphql\n\tquery projectSites($first: Int! $after: String) {\n\t\tprojectSites(first: $first, after: $after) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\t...ProjectSiteCard_ProjectSiteFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["#graphql\n\tquery projectSites($first: Int! $after: String) {\n\t\tprojectSites(first: $first, after: $after) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\t...ProjectSiteCard_ProjectSiteFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery projectSiteForModal($id: ID!) {\n\t\tgetProjectSite(id: $id) {\n\t\t\t...ProjectSiteModal_ProjectSiteFragment\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery projectSiteForModal($id: ID!) {\n\t\tgetProjectSite(id: $id) {\n\t\t\t...ProjectSiteModal_ProjectSiteFragment\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery projectSitesForModuleStats($first: Int $after: String $hasUrl: Boolean) {\n\t\tprojectSites(first: $first, after: $after, hasUrl: $hasUrl) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery projectSitesForModuleStats($first: Int $after: String $hasUrl: Boolean) {\n\t\tprojectSites(first: $first, after: $after, hasUrl: $hasUrl) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery modulesByProjectSite($id: ID!) {\n\t\tmodulesByProjectSite(id: $id) {\n\t\t\tid\n\t\t\ttitle\n\t\t\ttype\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery modulesByProjectSite($id: ID!) {\n\t\tmodulesByProjectSite(id: $id) {\n\t\t\tid\n\t\t\ttitle\n\t\t\ttype\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProjectCard_ProjectFragment on Project {\n\t\tname\n\t\tstatus\n\t\tdescription\n\t\trealEstateObjects {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ProjectCard_ProjectFragment on Project {\n\t\tname\n\t\tstatus\n\t\tdescription\n\t\trealEstateObjects {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProjectSiteCard_ProjectSiteFragment on ProjectSite {\n\t\tid\n\t\tname\n\t\turl\n\t\tthemeVersion\n\t\tlastSyncedOn\n\t\tscreenCapture\n\t\trepository {\n\t\t\turl\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ProjectSiteCard_ProjectSiteFragment on ProjectSite {\n\t\tid\n\t\tname\n\t\turl\n\t\tthemeVersion\n\t\tlastSyncedOn\n\t\tscreenCapture\n\t\trepository {\n\t\t\turl\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProjectSiteModal_ProjectSiteFragment on ProjectSite {\n\t\tid\n\t\tname\n\t\tprojects {\n\t\t\tid\n\t\t\t...ProjectCard_ProjectFragment\n\t\t}\n\t\trealEstateObjects {\n\t\t\tid\n\t\t\t...RealEstateObjectCard_RealEstateObjectFragment\n\t\t}\n\t}\n"): (typeof documents)["\n\tfragment ProjectSiteModal_ProjectSiteFragment on ProjectSite {\n\t\tid\n\t\tname\n\t\tprojects {\n\t\t\tid\n\t\t\t...ProjectCard_ProjectFragment\n\t\t}\n\t\trealEstateObjects {\n\t\t\tid\n\t\t\t...RealEstateObjectCard_RealEstateObjectFragment\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment RealEstateObjectCard_RealEstateObjectFragment on RealEstateObject {\n\t\tid\n\t\tnumber\n\t\tstatus\n\t\tprice\n\t}\n"): (typeof documents)["\n\tfragment RealEstateObjectCard_RealEstateObjectFragment on RealEstateObject {\n\t\tid\n\t\tnumber\n\t\tstatus\n\t\tprice\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "#graphql\n\tquery searchProjectSites($query: String! $first: Int, $after: String) {\n\t\tsearchProjectSites(query: $query, first: $first, after: $after) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\thasPreviousPage\n\t\t\t\tstartCursor\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\t...ProjectSiteCard_ProjectSiteFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["#graphql\n\tquery searchProjectSites($query: String! $first: Int, $after: String) {\n\t\tsearchProjectSites(query: $query, first: $first, after: $after) {\n\t\t\ttotalCount\n\t\t\tpageInfo {\n\t\t\t\thasNextPage\n\t\t\t\thasPreviousPage\n\t\t\t\tstartCursor\n\t\t\t\tendCursor\n\t\t\t}\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\t...ProjectSiteCard_ProjectSiteFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;