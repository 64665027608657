import styles from './IconButton.module.css';
import React from 'react';

import InfoIcon from '../icons/InfoIcon';
import WarningIcon from '../icons/WarningIcon';
import ErrorIcon from '../icons/ErrorIcon';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import EllipsisVertIcon from '../icons/EllipsisVertIcon';

interface Icons {
	info: JSX.Element,
	error: JSX.Element,
	warning: JSX.Element,
	success: JSX.Element,
	'ellipsis-vert': JSX.Element,
}

const icons: Icons = {
	info: <InfoIcon />,
	error: <ErrorIcon />,
	warning: <WarningIcon />,
	success: <CheckCircleIcon />,
	'ellipsis-vert': <EllipsisVertIcon />
};

/**
 * @param {object} props
 * @param {'info' | 'error' | 'warning' | 'success' | 'ellipsis-vert'} props.icon
 * @param {React.MouseEventHandler<HTMLButtonElement> | undefined} props.onClick
 * @returns
 */
export default function IconButton({
	icon,
	onClick,
}: {
	icon: keyof Icons,
	onClick: React.MouseEventHandler<HTMLButtonElement>,
}) {
	return (
		<button className={styles.container} onClick={onClick}>
			{icons[icon]}
		</button>
	);
}