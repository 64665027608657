import styles from './Alert.module.css';

import InfoIcon from '../icons/InfoIcon';
import WarningIcon from '../icons/WarningIcon';
import ErrorIcon from '../icons/ErrorIcon';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import React from 'react';

const icons = {
	info: <InfoIcon />,
	error: <ErrorIcon />,
	warning: <WarningIcon />,
	success: <CheckCircleIcon />
};

export default function Alert({ children, severity = 'info' } : {
	children: React.ReactNode,
	severity?: 'info' | 'warning' | 'error' | 'success'
}) {
	return (
		<div className={`${styles.container}${styles[severity] ? ` ${styles[severity]}` : ''}`}>
			<div className={styles.icon}>
				{icons[severity]}
			</div>
			<div>
				{children}
			</div>
		</div>
	);
}