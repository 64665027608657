import styles from './RealEstateObjectCard.module.css';

import Label from '../../atoms/Label';

import { FormattedNumber } from 'react-intl';

import { FragmentType, useFragment } from '../../../types/gql/fragment-masking';
import { graphql } from '../../../types/gql';

const RealEstateObjectCard_RealEstateObjectFragment = graphql(/* GraphQL */ `
	fragment RealEstateObjectCard_RealEstateObjectFragment on RealEstateObject {
		id
		number
		status
		price
	}
`);

export default function RealEstateObjectCard({
	realEstateObject,
} : {
	realEstateObject: FragmentType<typeof RealEstateObjectCard_RealEstateObjectFragment>,
}) {
	const { number, status, price } = useFragment(RealEstateObjectCard_RealEstateObjectFragment, realEstateObject);

	return (
		<div className={styles.container}>
			<div className={styles.body}>
				<p>
					Number: <strong>{number}</strong><br />
					Status: <Label>{status}</Label><br />
					{price && <>
						Price: <strong><FormattedNumber value={price} style="currency" currency="EUR" /></strong>
					</>}
				</p>
			</div>
		</div>
	);
}
