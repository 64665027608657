import styles from './ProgressBar.module.css';

export default function ProgressBar({
	now = 0
} : {
	now?: number
}) {
	return (
		<span className={styles.container}>
			<span className={styles.bar} style={{ width: + Math.min(now, 100) + '%' }} />
		</span>
	);
}