import { useEffect, useRef } from 'react';

export default function useOutsideClick(callback: () => void) {
	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
				callback();
			}
		};

		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [ref, callback]);

	return ref;
};