import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './App.module.css';

import { useQuery, useLazyQuery } from '@apollo/client';
import { graphql } from './types/gql/gql';

import Skeleton from './components/atoms/Skeleton';
import Alert from './components/atoms/Alert';
import IconButton from './components/atoms/IconButton';
import MenuItem from './components/atoms/MenuItem';

import ProjectSiteCard from './components/molecules/ProjectSiteCard';

import ProjectSiteModal from './components/molecules/ProjectSiteModal';
import ModuleStatsModal from './components/molecules/ModuleStatsModal';

import Menu from './components/molecules/Menu';

import SearchProjectSiteForm from './components/organisms/SearchProjectSiteForm';

import useOutsideClick from './hooks/useOutsideClick';

const ProjectSites_Query = graphql(`#graphql
	query projectSites($first: Int! $after: String) {
		projectSites(first: $first, after: $after) {
			totalCount
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					id
					...ProjectSiteCard_ProjectSiteFragment
				}
			}
		}
	}
`);

const ProjectSiteModal_Query = graphql(`
	query projectSiteForModal($id: ID!) {
		getProjectSite(id: $id) {
			...ProjectSiteModal_ProjectSiteFragment
		}
	}
`);

function App() {
	const { data, loading, error, fetchMore } = useQuery(ProjectSites_Query, {
		variables: {
			first: 18
		}
	});
	const [fetchProjectSite, { data: projectSiteData, loading: loadingProjectSite, error: errorProjectsite }] = useLazyQuery(ProjectSiteModal_Query);
	const [isModalOpen, setModalOpen] = useState(false);
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isModuleModalOpen, setModuleModalOpen] = useState(false);
	const menuRef = useOutsideClick(() => setMenuOpen(false));

	const loadMore = useCallback(() => {
		if (!data) {
			return;
		}

		fetchMore({
			variables: {
				after: data.projectSites.pageInfo.endCursor
			}
		});
	}, [fetchMore, data]);

	const openProjectSiteModal = useCallback((id: string) => {
		fetchProjectSite({
			variables: {
				id
			}
		});

		setModalOpen(true);
	}, [fetchProjectSite]);

	return (
		<div className={styles.app}>
			<header className={styles.header}>
				<SearchProjectSiteForm />
			</header>
			<div className={styles.body}>
				<div className={styles.bodyHeader}>
					<h1>
						Project sites ({data?.projectSites.totalCount || '0'})
					</h1>
					<div className={styles.menuContainer}>
						<IconButton icon="ellipsis-vert" onClick={() => setMenuOpen(current => !current)} />
						{isMenuOpen &&
							// @ts-ignore
							<div className={styles.menu} ref={menuRef}>
								<Menu>
									<MenuItem onClick={() => setModuleModalOpen(true)}>
										Modules
									</MenuItem>
								</Menu>
							</div>
						}
					</div>
				</div>

				{error && <Alert severity="error">{error.message}</Alert>}
				{data?.projectSites.totalCount === 0 && <p>No projects to display</p>}
				{data && data.projectSites.totalCount > 0 && <ul className={styles.projectSitesContainer}>
					{data?.projectSites.edges.map(({ node }) => (
						<li key={node.id}>
							<ProjectSiteCard projectSite={node} onOpen={openProjectSiteModal} />
						</li>
					))}
				</ul>}
				{loading && <ul className={styles.projectSitesContainer}>
					{Array.from(Array(5)).map((value, index) => <li key={index}>
							<Skeleton width="100%" height="250px" />
						</li>)}
					</ul>}
				{data?.projectSites.pageInfo.hasNextPage &&
					<button
							onClick={loadMore}
							disabled={loading}
							className={`${styles.moreButton}${loading ? ` ${styles.moreLoading}` : ''}`}
						>
							<div className={styles.spinner} />
					</button>
					}
			</div>
			{createPortal(
				<ProjectSiteModal
					projectSite={projectSiteData?.getProjectSite}
					isOpen={isModalOpen}
					onHide={() => setModalOpen(false)}
				/>,
				document.body
			)}
			{createPortal(
				<ModuleStatsModal
					isOpen={isModuleModalOpen}
					onHide={() => setModuleModalOpen(false)}
				/>,
				document.body
			)}
		</div>
	);
}

export default App;
