import styles from './CrossIcon.module.css';

export default function CrossIcon({
	strokeClass,
}: {
	strokeClass?: string,
}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.88 20.88">
			<line x1="0.44" y1="20.44" x2="20.44" y2="0.44" className={`${strokeClass ? `${strokeClass} ` : ''} ${styles.stroke}`}  />
			<line x1="20.44" y1="20.44" x2="0.44" y2="0.44" className={`${strokeClass ? `${strokeClass} ` : ''} ${styles.stroke}`}  />
		</svg>
	);
}
