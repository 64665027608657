import styles from './ModuleStatsModal.module.css';
import { useState, useCallback, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import Modal from '../Modal';

import Button from '../../atoms/Button';
import Alert from '../../atoms/Alert';

import ProgressBar from '../ProgressBar';

import { graphql } from '../../../types/gql';
import { ProjectSitesForModuleStatsQuery, ModulesByProjectSiteQuery } from '../../../types/gql/graphql';


const ProjectSitesForModuleStats_Query = graphql(`
	query projectSitesForModuleStats($first: Int $after: String $hasUrl: Boolean) {
		projectSites(first: $first, after: $after, hasUrl: $hasUrl) {
			totalCount
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					id
					url
				}
			}
		}
	}
`);

const ProjectSitesModule_Query = graphql(`
	query modulesByProjectSite($id: ID!) {
		modulesByProjectSite(id: $id) {
			id
			title
			type
		}
	}
`);

interface ModuleTypeStat {
	type: string,
	moduleCount: number,
}

const processModules = (modulesBySites: { site: ProjectSitesForModuleStatsQuery['projectSites']['edges'][0]['node'], modules: ModulesByProjectSiteQuery['modulesByProjectSite']}[]) : ModuleTypeStat[] => {
	const modules = modulesBySites.map(modulesBySite => modulesBySite.modules).flat();

	// @ts-ignore
	const results = Object.groupBy(modules, ({ type }: ModulesByProjectSiteQuery['modulesByProjectSite'][0]) => type);

	const moduleStats: ModuleTypeStat[] = [];
	Object.keys(results).forEach((key: string) => {
		const modules: ModulesByProjectSiteQuery['modulesByProjectSite'][0][] = results[key];
		moduleStats.push({
			type: modules[0].type,
			moduleCount: modules.length,
		});
	});

	return moduleStats.sort((a, b) => {
		if (a.moduleCount > b.moduleCount) {
			return -1;
		  } else if (a.moduleCount < b.moduleCount) {
			return 1;
		  }
		  // a must be equal to b
		  return 0;
	});
}

export default function ModuleStatsModal({
	isOpen = false,
	onHide,
}: {
	isOpen?: boolean,
	onHide: () => void,
}) {
	const [getSites, { data, loading, error, fetchMore, called }] = useLazyQuery(ProjectSitesForModuleStats_Query, {
		variables: {
			first: 100,
			hasUrl: true,
		},
	});

	const [getModules, { data: modulesData, loading: modulesLoading, error: modulesError, variables: moduleVariables }] = useLazyQuery(ProjectSitesModule_Query);
	const [progress, setProgress] = useState(0);
	const [progressMessage, setProgressMessage] = useState<string | null>(null);
	const [siteToCheck, setSiteToCheck] = useState<ProjectSitesForModuleStatsQuery['projectSites']['edges'][0]['node'] | null>(null);
	const [sitesToCheck, setSitesToCheck] = useState<ProjectSitesForModuleStatsQuery['projectSites']['edges'][0]['node'][] | null>(null);
	const [sitesWithModules, setSitesWithModules] = useState<{ site: ProjectSitesForModuleStatsQuery['projectSites']['edges'][0]['node'], modules: ModulesByProjectSiteQuery['modulesByProjectSite']}[]>([]);
	const [moduleStats, setModuleStats] = useState<ModuleTypeStat[] | null>(null);

	const calculateStats = useCallback(() => {
		getSites();
		setProgressMessage('Getting project sites');
	}, [getSites]);

	useEffect(() => {
		if (!data) {
			return;
		}

		setProgress(data.projectSites.edges.length / data.projectSites.totalCount * 10);

		if (data.projectSites.pageInfo.hasNextPage) {
			fetchMore({
				variables: {
					after: data.projectSites.pageInfo.endCursor
				}
			})
		}
	}, [data, fetchMore]);

	useEffect(() => {
		if (!data?.projectSites || data.projectSites.pageInfo.hasNextPage) {
			return;
		}
		setProgressMessage('Getting modules from every individual site in series');
		setSitesToCheck(data.projectSites.edges.map(edge => edge.node))
	}, [data]);

	useEffect(() => {
		if (!sitesToCheck) {
			return;
		}

		setSiteToCheck(sitesToCheck[0]);
	}, [sitesToCheck]);

	useEffect(() => {
		if (!sitesToCheck) {
			return;
		}

		if (sitesToCheck.length === 0) {
			setModuleStats(processModules(sitesWithModules));
		}
	}, [sitesToCheck, sitesWithModules]);

	useEffect(() => {
		if (!siteToCheck) {
			return;
		}

		getModules({
			variables: {
				id: siteToCheck.id,
			}
		});
	}, [siteToCheck, getModules]);

	useEffect(() => {
		if (modulesData && siteToCheck && siteToCheck?.id === moduleVariables?.id) {
			setSitesWithModules(prev => prev.concat([{
				site: siteToCheck,
				modules: modulesData.modulesByProjectSite,
			}]));
			setSitesToCheck(prev => Array.isArray(prev) ? prev.filter(site => site.id !== siteToCheck.id) : prev);
		}
	}, [modulesData, siteToCheck, moduleVariables]);

	useEffect(() => {
		if (modulesError && siteToCheck) {
			setSitesToCheck(prev => Array.isArray(prev) ? prev.filter(site => site.id !== siteToCheck.id) : prev);
		}
	}, [modulesError, siteToCheck]);

	useEffect(() => {
		if (!data) {
			return;
		}

		setProgress(((sitesWithModules.length / data?.projectSites.totalCount * 0.8) + 0.2) * 100);
	}, [sitesWithModules, data]);

	return (
		<Modal
			title="Module stats"
			isOpen={isOpen}
			onHide={onHide}
			buttons={[
				{
					label: 'Close',
					type: 'secondary',
					onClick: () => onHide(),
				}
			]}
		>
			<p>
				De module statistieken worden opgehaald van sites die voldoen aan de volgende voorwaarden:
			</p>
			<ol>
				<li>Hoger of gelijk aan versie 2.50.0</li>
				<li>Url is bekend</li>
				<li>Access token is toegevoegd aan de site</li>
			</ol>
			{error && <Alert severity="error">{error.message}</Alert>}
			{moduleStats === null && <div className={styles.progress}>
				{progressMessage && <p>
					{progressMessage}
				</p>}
				<ProgressBar now={progress} />
			</div>}
			{moduleStats && moduleStats.length > 0 &&
				<table className={styles.stats}>
					<thead>
						<tr>
							<th>
								Type
							</th>
							<th>
								Count
							</th>
						</tr>
					</thead>
					<tbody>
						{moduleStats.map(moduleStat => <tr key={moduleStat.type}>
							<td>{moduleStat.type}</td>
							<td>{moduleStat.moduleCount}</td>
						</tr>)}
					</tbody>
				</table>
			}
			{moduleStats === null && <Button loading={loading || modulesLoading} disabled={called} onClick={calculateStats}>Calculate stats</Button>}
		</Modal>
	);
}
