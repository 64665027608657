import styles from './SearchResults.module.css';
import { ReactElement, MouseEventHandler } from 'react';

import Button from '../../atoms/Button';
import Skeleton from '../../atoms/Skeleton';

export default function SearchResults({
	totalCount,
	results = [],
	query = '',
	loading = false,
	onLoadMore,
}: {
	totalCount?: number,
	results: ReactElement[],
	query?: string,
	loading?: boolean,
	onLoadMore?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>,
}) {
	return (
		<div className={styles.container}>
			{((totalCount && totalCount > 0) || loading) && <div className={styles.header}>
				{(totalCount && totalCount > 0)? `${totalCount} resulta${totalCount === 1 ? 'at' : 'ten'}` : ''}
				{totalCount && totalCount < 0 && loading ? <Skeleton /> : ''}
			</div>}
			{loading && (totalCount === 0 || typeof totalCount === 'undefined') && <ul className={`${styles.skeletonContainer}${loading ? ` ${styles.isLoading}`: ''}`}>
				<li><Skeleton width="100%" height="50px" display="block" /></li>
				<li><Skeleton width="100%" height="50px" display="block" /></li>
				<li><Skeleton width="100%" height="50px" display="block" /></li>
			</ul>}
			{results.length > 0 && <ul className={loading && styles.isLoading || undefined}>
				{results.map(r => <li key={r.key}>{r}</li>)}
			</ul>}
			<div className={styles.footer}>
				{!loading && totalCount === 0 && <div className={styles.noResults}>
					<div>
						Geen resultaten voor <strong>{query}</strong>
					</div>
				</div>}
				{onLoadMore && <Button type="secondary" onClick={onLoadMore}>Meer laden...</Button>}
			</div>
		</div>
	);
}
