import styles from './SearchIcon.module.css';

export default function SearchIcon({ title }: { title?: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
			{title && <title>{title}</title>}
			<path
				className={styles.fill}
				d="M33.8,36L20.6,22.8c-1,0.9-2.2,1.5-3.5,2c-1.3,0.5-2.8,0.7-4.2,0.7c-3.6,0-6.6-1.2-9.1-3.8S0,16.3,0,12.8s1.2-6.6,3.8-9.1S9.3,0,12.9,0c3.5,0,6.5,1.2,9,3.8s3.7,5.5,3.7,9.1c0,1.4-0.2,2.8-0.7,4.2c-0.5,1.3-1.2,2.6-2.1,3.8L36,33.8L33.8,36z M12.8,22.6c2.7,0,5-1,6.9-2.9c1.9-1.9,2.9-4.2,2.9-6.9s-1-5-2.9-6.9S15.6,3,12.9,3c-2.7,0-5.1,1-7,2.9S3,10.1,3,12.8s1,5,2.9,6.9S10.1,22.6,12.8,22.6z"
			/>
		</svg>
	);
}
