import React from 'react';
import styles from './Modal.module.css';

import Button, { ButtonProps } from '../../atoms/Button';
import { useCallback, useEffect, useRef } from 'react';

interface ButtonProperties extends Omit<ButtonProps, 'children'> {
	label: string,
}

export default function Modal({
	children,
	isOpen = false,
	onHide,
	title,
	buttons = [],
}: {
	children: React.ReactNode,
	isOpen?: boolean,
	onHide?: () => void,
	title?: React.ReactNode,
	buttons?: ButtonProperties[],
}) {
	const wrapperRef = useRef<HTMLDivElement>(null);

	const onClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (typeof onHide === 'function' && (wrapperRef.current === e.target || (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)))) {
			onHide();
		}
	},[onHide, wrapperRef]);

	useEffect(() => {
		if (isOpen === true) {
			document.body.classList.add('is-modal-open');
		}
		else {
			document.body.classList.remove('is-modal-open');
		}
	}, [isOpen]);

	return (
		<div ref={wrapperRef} className={styles.wrapper + (isOpen ? ' ' + styles.isOpen : '')} onClick={onClick} aria-hidden={!isOpen} role="dialog" aria-modal="true">
			<div className={styles.container}>
				<header className={styles.headerContainer}>{title}</header>
				<div className={styles.bodyContainer}>
					{children}
				</div>
				{buttons.length > 0 ?
					<footer className={styles.footerContainer}>
						{buttons.map(button => (
							<Button key={button.label} {...button}>{button.label}</Button>
						))}
					</footer>
					: ''}
			</div>
		</div>
	);
}
