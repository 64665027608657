// @ts-check
import React from 'react';
import styles from './Button.module.css';

export interface ButtonProps {
	children: React.ReactNode,
	type?: 'primary' | 'secondary' | 'tertiary',
	disabled?: boolean,
	loading?: boolean,
	isSubmit?: boolean,
	onClick: React.MouseEventHandler<HTMLButtonElement>,
}

export default function Button({
	children,
	type = 'primary',
	disabled = false,
	loading = false,
	isSubmit = false,
	onClick,
} : ButtonProps) {
	return (
			<button
				type={!isSubmit ? 'button' : 'submit'}
				onClick={onClick}
				disabled={disabled}
				className={styles.button + (type !== 'primary' ? ' ' + styles[type] : '') + (loading ? ' ' + styles.loading : '')}
			>
				{children}
			</button>
	);
};