import styles from './EllipsisVertIcon.module.css';

/**
 * @param {object} props
 * @param {string=} props.title
 */
export default function EllipsisVertIcon({
	title
 }: {
	title?: string
 }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 16">
			{title && <title>{title}</title>}
			<path
				className={styles.fill}
				d="m2,16c-.55,0-1.02-.2-1.41-.59s-.59-.86-.59-1.41.2-1.02.59-1.41.86-.59,1.41-.59,1.02.2,1.41.59.59.86.59,1.41-.2,1.02-.59,1.41-.86.59-1.41.59Zm0-6c-.55,0-1.02-.2-1.41-.59s-.59-.86-.59-1.41.2-1.02.59-1.41c.39-.39.86-.59,1.41-.59s1.02.2,1.41.59c.39.39.59.86.59,1.41s-.2,1.02-.59,1.41-.86.59-1.41.59Zm0-6c-.55,0-1.02-.2-1.41-.59s-.59-.86-.59-1.41S.2.98.59.59s.86-.59,1.41-.59,1.02.2,1.41.59.59.86.59,1.41-.2,1.02-.59,1.41-.86.59-1.41.59Z" />
		</svg>
	);
}