import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './global.css';
import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';
import App from './App';

import { IntlProvider } from 'react-intl';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
		<ApolloProvider client={client}>
			<IntlProvider locale={navigator.language}>
				<App />
			</IntlProvider>
		</ApolloProvider>
);
