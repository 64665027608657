import styles from './ProjectSiteCard.module.css';
import { useCallback } from 'react';

import { DateTime } from 'luxon';

import { FragmentType, useFragment } from '../../../types/gql/fragment-masking';
import { graphql } from '../../../types/gql';

import bitbucketIcon from './icon-bitbucket.svg';

const ProjectSiteCard_ProjectSiteFragment = graphql(/* GraphQL */ `
	fragment ProjectSiteCard_ProjectSiteFragment on ProjectSite {
		id
		name
		url
		themeVersion
		lastSyncedOn
		screenCapture
		repository {
			url
		}
	}
`);

export default function ProjectSiteCard({
	projectSite,
	onOpen,
} : {
	projectSite: FragmentType<typeof ProjectSiteCard_ProjectSiteFragment>,
	onOpen?: (id: string) => void
}) {
	const { id, name, url, lastSyncedOn, themeVersion, screenCapture, repository } = useFragment(ProjectSiteCard_ProjectSiteFragment, projectSite);

	const onOpenHandler = useCallback(() => {
		if (typeof onOpen === 'function') {
			onOpen(id);
		}
	}, [onOpen, id]);

	return (
		<div className={styles.container}>
			<div className={styles.screenCaptureContainer}>
				{screenCapture ?
					<img src={screenCapture} width="384px" height="240px" alt={`screenshot of ${url}`} /> :
					<span className={styles.screenCaptureNotification}>Geen schermafbeelding beschikbaar</span>}
				{lastSyncedOn && <time className={styles.lastSyncedTime}>{DateTime.fromSeconds(lastSyncedOn).toLocaleString(DateTime.DATETIME_SHORT)}</time>}
			</div>
			<div className={styles.body}>
				<h2 className={styles.name}>{onOpen ? <button onClick={onOpenHandler}>{name}</button> : name}</h2>
				<div className={styles.sub}>
					{themeVersion && <span className={styles.themeVersion}>{themeVersion}</span>}
					{url && <a href={url}>{new URL(url).hostname}</a>}
				</div>
			</div>
			<div className={styles.footer}>
				{repository.url &&<a href={repository.url} title={`${name} on Bitbucket`}><img src={bitbucketIcon} width="256" height="231" alt="bitbucket icon" /></a>}
			</div>
		</div>
	);
}
