import styles from './SearchField.module.css';
import React, { useEffect, useState } from 'react';

import SearchIcon from '../../atoms/icons/SearchIcon';
import CrossIcon from '../../atoms/icons/CrossIcon';
import InputContainer from '../../atoms/InputContainer';

import { useDebounce } from 'use-debounce'; // TODO: overkill for such a simple use case, make our own hook

export default function SearchField({
	name,
	value,
	placeholder = undefined,
	onChange,
	isFocused = false,
	onFocusChange,
	resizeInputOnFocus = true,
	delay = 500
}: {
	name: string,
	value?: string,
	placeholder?: string,
	onChange?: (name: string, query: string | null) => void,
	isFocused?: boolean,
	onFocusChange?: React.FocusEventHandler<HTMLInputElement>,
	resizeInputOnFocus?: boolean,
	delay?: number
}) {
	const [internalValue, setValue] = useState<string | null | undefined>(value);
	const [query] = useDebounce(internalValue, delay);

	useEffect(() => {
		if (typeof onChange === 'function') {
			onChange(name, query ?? null);
		}
	}, [name, query, onChange]);

	const clear = () => {
		setValue('');
	};

	useEffect(() => {
		setValue(value);
	}, [value]);

	return (
		<div className={`${styles.container}${isFocused || !resizeInputOnFocus ? ` ${styles.isFullWidth}` : ''}`}>
			<InputContainer key={name}>
				<div className={styles.iconContainer}><SearchIcon /></div>
				<input
					name={name || 'search'}
					type="search"
					placeholder={placeholder}
					value={internalValue ?? undefined}
					onFocus={onFocusChange}
					onBlur={onFocusChange}
					onChange={(event) => setValue(event.target.value)}
				/>
				{isFocused && query && <div onClick={clear} className={styles.iconContainer}>
					<CrossIcon strokeClass={styles.clearShape} />
				</div>}
			</InputContainer>
		</div>
	);
}
