import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

const client = new ApolloClient({
	uri: process.env.REACT_APP_PUBLIC_API_ENDPOINT,
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					projectSites: relayStylePagination(),
					searchProjectSites: relayStylePagination(),
				}
			},
		},
	}),
	connectToDevTools: process.env.NODE_ENV !== 'production',
});

export default client;